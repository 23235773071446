import { useCallback, useMemo, useState } from 'react'
import { Person } from 'people/interface'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'

export const useFetchPeople = () => {
  const [loading, setLoading] = useState(false)
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [people, setPeople] = useState<Person[]>([])
  const [request] = useFetchRefactored()

  const fetchPeople = useCallback(
    (queryStringParameters: { limit: number; offset: number }) => {
      return request({
        url: `/people`,
        config: {
          queryStringParameters,
        },
      }) as Promise<Person[]>
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const countPeople = useCallback(
    () => {
      return request({
        url: `/people-count`,
      }) as Promise<{ count: number }>
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPeople = useCallback(async () => {
    setLoadingProgress(0)
    setLoading(true)
    const newPeople: Person[] = []
    const { count } = await countPeople()

    // not part of .env as different endpoints have different optimal values
    const limit = 5000
    for (let offset = 0; offset < count; offset += limit) {
      newPeople.push(...(await fetchPeople({ limit, offset })))
      setLoadingProgress(Math.min(100, Math.round(((offset + limit) / count) * 100)))
    }

    setLoadingProgress(100)
    setLoading(false)
    setPeople(newPeople)
    setInitiallyLoaded(true)
  }, [countPeople, fetchPeople])

  return useMemo(
    () => ({
      people,
      loading,
      initiallyLoaded,
      loadingProgress,
      getPeople,
    }),
    [loading, loadingProgress, people, initiallyLoaded], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
