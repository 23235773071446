import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OpportunityVisibility } from 'Event/interfaces'
import { FormModal } from 'components/FormModal'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { EventInfoScreen, EventInfoScreenValues } from 'Event/components/EventInfoScreen'
import { useGroups } from 'group/hooks/useGroups'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { EventGeofencing, mapEventGeofencingToGeofencing, mapGeofencingToEventGeofencing } from 'utils/event'
import { useEnabledGroups } from 'Event/components/opportunity/GroupPicker/hooks'
import { EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import Loading from 'civic-champs-shared/core/Loading'
import {
  OpportunityTemplate,
  OpportunityTemplatePayload,
  OpportunityTemplatePersonGroupPayload,
} from 'volunteering/opportunities/interfaces'
import { useGetOpportunityTemplateGroups } from 'volunteering/opportunities/hooks/useGetOpportunityTemplateGroups'
import { useFeatureEnabled } from 'core/feature/hooks'
import { FormReferenceItem } from 'Event/helpers/add-edit-helpers'
import useGetOpportunityTemplateSurveys from 'volunteering/opportunities/hooks/useGetOpportunityTemplateSurveys'
import useGetOpportunityTemplateCredentials from 'volunteering/opportunities/hooks/useGetOpportunityTemplateCredentials'
import { Geofencing } from 'civic-champs-shared/core/location/utils'
import {
  EventRegistrationDetailsScreen,
  EventRegistrationDetailsScreenValues,
} from 'Event/components/EventRegistrationDetailsScreen'

interface Props {
  showing: boolean
  complete: (result: {
    values: OpportunityTemplatePayload
    groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
    questionnaires: number[]
    waivers: number[]
  }) => void
  close: () => void
  opportunityTemplate?: OpportunityTemplate
}

interface FormData extends Omit<OpportunityTemplatePayload, 'geofencing'> {
  visibilityGroups: EventGroup[]
  onboardingGroups: EventGroup[]
  questionnaires: FormReferenceItem[]
  waivers: FormReferenceItem[]
  geofencing: Geofencing
  locationIsAddress: boolean
}

const defaultValues: Omit<FormData, 'isSchedulable'> = {
  name: '',
  description: '',
  isActive: true,
  geofencing: DEFAULT_GEOFENCING,
  streetAddress: '',
  city: '',
  state: '',
  zip: '',
  visibility: OpportunityVisibility.PUBLIC,
  visibilityGroups: [],
  onboardingGroups: [],
  questionnaires: [],
  waivers: [],
  locationDetails: '',
  isRecurring: false,
  recurrencePattern: null,
  isPrivate: false,
  contactName: '',
  contactEmail: '',
  contactPhoneNumber: '',
  instructions: '',
  locationIsAddress: true,
}

const mapFormDataToRegistrationScreenValues = (formData?: FormData): EventRegistrationDetailsScreenValues => {
  const values = formData || defaultValues
  return {
    onboardingGroups: values.onboardingGroups,
    questionnaires: values.questionnaires,
    waivers: values.waivers,
    instructions: values.instructions || '',
    visibility: values.visibility,
  }
}

const mapFormDataToEventScreenValues = (formData?: FormData): EventInfoScreenValues => {
  const values = formData || defaultValues
  return {
    name: values.name,
    description: values.description || '',
    address: values.streetAddress || '',
    city: values.city || '',
    state: values.state || '',
    zip: values.zip || '',
    visibility: values.visibility,
    geofencing: values.geofencing,
    visibilityGroups: values.visibilityGroups,
    locationIsAddress: values.locationIsAddress,
    locationDetails: values.locationDetails || '',
    isActive: values.isActive,
    contactEmail: values.contactEmail || '',
    contactName: values.contactName || '',
    contactPhoneNumber: values.contactPhoneNumber || '',
  }
}

const mapOpportunityTemplateToFormData = (
  { geofencing, ...opportunityTemplate }: OpportunityTemplate,
  groups: EventGroup[],
  questionnaires: FormReferenceItem[],
  waivers: FormReferenceItem[],
): FormData => ({
  ...opportunityTemplate,
  geofencing: mapEventGeofencingToGeofencing(geofencing as EventGeofencing),
  visibilityGroups: opportunityTemplate.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY ? groups : [],
  onboardingGroups: opportunityTemplate.visibility !== OpportunityVisibility.SELECT_GROUPS_ONLY ? groups : [],
  questionnaires,
  waivers,
})

const mapInfoValuesToFormData = ({ address, ...values }: EventInfoScreenValues): Partial<FormData> => ({
  ...values,
  isActive: values.isActive || false,
  streetAddress: address,
  isPrivate: values.visibility === OpportunityVisibility.PRIVATE,
})

const mapEventGroupToOpportunityTemplateGroup = (
  group: EventGroup,
): Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'> => ({
  groupId: group.groupId,
  associationType: group.associationType,
  approvedMembersOnly: group.approvedMembersOnly,
})

export const AddEditOpportunityTemplatePrompt = ({ showing, close, complete, opportunityTemplate }: Props) => {
  const { groups: initialGroups, loading: groupsLoading } = useGroups() as { groups: Group[]; loading: boolean }
  const groups = useEnabledGroups(initialGroups)
  const [getOpportunityTemplateGroups, { loading: templateGroupsLoading }] = useGetOpportunityTemplateGroups()
  const [getOpportunityTemplateSurveys, { loading: templateSurveysLoading }] = useGetOpportunityTemplateSurveys()
  const [getOpportunityTemplateCredentials, { loading: templateCredentialsLoading }] =
    useGetOpportunityTemplateCredentials()
  const isCalendarEventSwitchEnabled = useFeatureEnabled('NewAddEventUICalendarEvent')
  const isSchedulable = useMemo(
    () => (opportunityTemplate ? opportunityTemplate.isSchedulable : isCalendarEventSwitchEnabled),
    [isCalendarEventSwitchEnabled, opportunityTemplate],
  )
  const [formData, setFormData] = useState<FormData>()
  const [screen, setScreen] = useState<0 | 1>(0)

  const [eventInfoValues, registrationDetailsValues] = useMemo(
    () => [mapFormDataToEventScreenValues(formData), mapFormDataToRegistrationScreenValues(formData)],
    [formData],
  )

  useEffect(() => {
    if (opportunityTemplate) {
      Promise.all([
        getOpportunityTemplateGroups(opportunityTemplate.id),
        getOpportunityTemplateSurveys(opportunityTemplate.id),
        getOpportunityTemplateCredentials(opportunityTemplate.id),
      ]).then(([opportunityTemplateGroups, opportunityTemplateSurveys, opportunityTemplateCredentials]) => {
        setFormData(
          mapOpportunityTemplateToFormData(
            opportunityTemplate,
            opportunityTemplateGroups.map(opportunityTemplateGroup => ({
              groupId: opportunityTemplateGroup.group.id,
              name: opportunityTemplateGroup.group.name,
              associationType: opportunityTemplateGroup.associationType,
              approvedMembersOnly: opportunityTemplateGroup.approvedMembersOnly,
              closed: opportunityTemplateGroup.group.closed,
            })) as EventGroup[],
            opportunityTemplateSurveys.map(({ survey: { questionSetName, id } }) => ({ name: questionSetName, id })),
            opportunityTemplateCredentials.map(({ credential: { id, name } }) => ({ name, id })),
          ),
        )
      })
    } else {
      setFormData({ ...defaultValues, isSchedulable: isCalendarEventSwitchEnabled })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onBack = useCallback(
    (data: EventRegistrationDetailsScreenValues) => {
      setScreen(0)
      setFormData({ ...formData, ...data } as FormData)
    },
    [formData],
  )

  const handleInfoSubmit = useCallback(
    (data: EventInfoScreenValues) => {
      setFormData({ ...formData, ...(mapInfoValuesToFormData(data) as FormData) })
      setScreen(1)
    },
    [formData],
  )

  const handleSubmit = useCallback(
    (data: EventRegistrationDetailsScreenValues) => {
      const {
        onboardingGroups,
        visibilityGroups,
        questionnaires,
        waivers,
        geofencing,
        locationIsAddress,
        locationDetails,
        streetAddress,
        city,
        state,
        zip,
        ...payload
      } = {
        ...formData,
        ...data,
      } as FormData
      complete({
        values: {
          ...payload,
          locationDetails: !locationIsAddress ? locationDetails : null,
          streetAddress: locationIsAddress ? streetAddress : null,
          city: locationIsAddress ? city : null,
          state: locationIsAddress ? state : null,
          zip: locationIsAddress ? zip : null,
          geofencing: mapGeofencingToEventGeofencing(geofencing as Geofencing),
        },
        groups: (payload.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY
          ? visibilityGroups
          : onboardingGroups
        ).map(mapEventGroupToOpportunityTemplateGroup),
        questionnaires: questionnaires.map(({ id }) => id),
        waivers: waivers.map(({ id }) => id),
      })
    },
    [complete, formData],
  )

  if (groupsLoading || templateGroupsLoading || templateSurveysLoading || templateCredentialsLoading) {
    return <Loading />
  }
  return (
    <FormModal
      id="opportunity-template-prompt"
      showing={showing}
      close={close}
      title={(opportunityTemplate ? 'Edit' : 'Add') + ' Event Template'}
    >
      {
        [
          <EventInfoScreen
            values={eventInfoValues}
            onBack={close}
            isNewEvent={!opportunityTemplate}
            onSubmit={handleInfoSubmit}
            groups={groups}
            isTemplate={true}
            isDraftable={false}
            isSchedulable={isSchedulable}
          />,
          <EventRegistrationDetailsScreen
            values={registrationDetailsValues}
            onBack={onBack}
            isNewEvent={!opportunityTemplate}
            isDraftable={false}
            onSubmit={handleSubmit}
            groups={groups}
            isSchedulable={isSchedulable}
          />,
        ][screen]
      }
    </FormModal>
  )
}

export default AddEditOpportunityTemplatePrompt
