import * as yup from 'yup'
import { DateSchema } from 'yup'
import moment from 'moment'
import { Field, FieldArray, Form, Formik } from 'formik'
import { FormModalScreen } from 'components/FormModal'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from 'civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import React, { useEffect } from 'react'
import { useAddEditEventStyles } from 'Event/hooks/useAddEditEventStyles'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import useGetOpportunityTemplates from 'volunteering/opportunities/hooks/useGetOpportunityTemplates'
import {
  RECURRING_TYPE,
  RECURRING_TYPE_OPTION,
  RECURRING_TYPE_OPTIONS,
} from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { filter } from 'lodash'
import StyledInput from 'civic-champs-shared/core/StyledInput'
import DeleteIcon from '@material-ui/icons/Delete'
import { Role } from 'volunteer-role/types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { InlineAddButton } from 'civic-champs-shared/core/InlineAddButton'
import { FormRole } from 'Event/helpers/add-edit-helpers'
import { useFeatureEnabled } from 'core/feature/hooks'
import cn from 'classnames'
import RadioGroupFormField from 'components/RadioGroupFormField'

export interface EventDateScreenValues {
  startDate: Date | null
  endDate: Date | null
  startTime: Date
  endTime: Date
  opportunityTemplate: OpportunityTemplate | null
  isSchedulable: boolean
  recurringType: RECURRING_TYPE
  roles: FormRole[]
  useTemplate: boolean
}

export interface EventDateScreenProps {
  values: EventDateScreenValues
  onSubmit: (values: EventDateScreenValues, asDraft?: boolean) => void
  onBack: () => void
  isNewEvent: boolean
  isDraftable: boolean
  roles: Role[]
  addRole: () => Promise<Role>
}

const eventDatePayloadSchema = yup.object({
  startDate: yup
    .date()
    .required('A start date is required. Please enter a start date.')
    .typeError('A start date is required. Please enter a start date.'),
  startTime: yup
    .date()
    .required('A start time is required. Please enter a start time.')
    .typeError('A start time is required. Please enter a start time.'),
  endDate: yup
    .date()
    .typeError('A end date is required. Please enter a end date.')
    .when(
      ['recurringType', 'isSchedulable'],
      (recurringType: RECURRING_TYPE, isSchedulable: boolean, schema: DateSchema) => {
        if (!isSchedulable || recurringType === RECURRING_TYPE.NEVER) return schema.nullable().notRequired()
        return schema
          .test('min-by-date', 'The end date cannot occur before the start date.', function (value: any) {
            return value && moment(value).isSameOrAfter(moment(this.parent.startDate), 'day')
          })
          .required('A end date is required. Please enter a end date.')
      },
    ),
  endTime: yup
    .date()
    .test('min-by-time', 'The end time cannot occur before the start time.', function (value: any) {
      if (!this.parent.startDate || !this.parent.startTime || !this.parent.endDate || !value) return true
      const startDate = moment(this.parent.startDate)
      const startTime = moment(this.parent.startTime).year(startDate.year()).dayOfYear(startDate.dayOfYear())
      const endDate = moment(this.parent.endDate)
      const endTime = moment(value).year(endDate.year()).dayOfYear(endDate.dayOfYear())
      return value && endTime.isSameOrAfter(startTime)
    })
    .required('A end time is required. Please enter a end time.')
    .typeError('A end time is required. Please enter a end time.'),
})

export const EventDateAndRoleScreen = ({
  values,
  onSubmit,
  onBack,
  isNewEvent,
  roles: allRoles,
  addRole,
  isDraftable,
}: EventDateScreenProps) => {
  const isCalendarEventSwitchEnabled = useFeatureEnabled('NewAddEventUICalendarEvent')
  const classes = useAddEditEventStyles()
  const [asDraft, setAsDraft] = React.useState(false)

  const [getOpportunityTemplates, { result: opportunityTemplates }] =
    useGetOpportunityTemplates(isCalendarEventSwitchEnabled)
  useEffect(() => {
    if (isNewEvent) {
      getOpportunityTemplates(true, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={values}
      validationSchema={eventDatePayloadSchema}
      onSubmit={values => onSubmit(values, asDraft)}
      validateOnChange
      isInitialValid={!isNewEvent}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        const recurringOptions = filter(
          RECURRING_TYPE_OPTIONS(values.startDate || new Date(), true),
          ({ value }) => value !== RECURRING_TYPE.CUSTOM_EVENTS,
        )
        const selectedRoles = new Set([...values.roles.map(({ roleId }) => roleId)])
        const handleAdd = async (index: number) => {
          const role = await addRole()
          setFieldValue(
            'roles',
            (values.roles as any).toSpliced(index, 1, { roleId: role.id, quantity: values.roles[index].quantity }),
          )
        }
        return (
          <Form>
            <FormModalScreen
              onBack={onBack}
              onNext={submitForm}
              onDraft={
                values.isSchedulable && isDraftable
                  ? () => {
                      setAsDraft(true)
                      submitForm()
                    }
                  : undefined
              }
              nextText="Next"
              draftText={values.isSchedulable && isDraftable ? 'Finish Later' : undefined}
              backText="Cancel"
              disabled={isSubmitting}
            >
              {isNewEvent && opportunityTemplates.length !== 0 && (
                <div className={cn(classes.section, classes.subSection)}>
                  <span className={classes.sectionTitle}>Event Details:</span>
                  <RadioGroupFormField
                    valueIsBoolean
                    name="useTemplate"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Create event from a Template',
                        value: true,
                      },
                    ]}
                  />
                  {values.useTemplate && (
                    <div className={classes.subDropdown}>
                      <AdvancedAutocomplete<OpportunityTemplate>
                        label={'Event Template'}
                        options={opportunityTemplates}
                        placeholder={'Select an Event Template'}
                        value={values.opportunityTemplate}
                        getOptionInfo={template => ({
                          left: template.name,
                          id: template.id,
                        })}
                        onChange={opportunityTemplate => setFieldValue('opportunityTemplate', opportunityTemplate)}
                      />
                    </div>
                  )}
                  <RadioGroupFormField
                    valueIsBoolean
                    name="useTemplate"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Create event from Scratch',
                        value: false,
                      },
                    ]}
                  />
                </div>
              )}

              <div className={classes.section}>
                <span className={classes.sectionTitle}>Event Scheduling:</span>
                {isNewEvent && isCalendarEventSwitchEnabled && (
                  <>
                    <div className={classes.dateTimePickers}>
                      <div className={classes.dateTimePicker}>
                        <Field
                          inputVariant="outlined"
                          label="Start Date *"
                          higher
                          component={StyledKeyboardDatePicker}
                          name="startDate"
                        />
                      </div>

                      <div className={classes.dateTimePicker}>
                        {values.recurringType !== RECURRING_TYPE.NEVER && (
                          <Field
                            higher
                            inputVariant="outlined"
                            label="End Date *"
                            component={StyledKeyboardDatePicker}
                            name="endDate"
                            minDate={moment(values.startDate || undefined)
                              .startOf('day')
                              .toDate()}
                            minDateMessage={'The end date cannot occur before the start date.'}
                          />
                        )}
                      </div>
                    </div>

                    <div className={classes.dateTimePickers}>
                      <div className={classes.dateTimePicker}>
                        <Field
                          inputVariant="outlined"
                          label="Start Time *"
                          higher
                          component={StyledKeyboardTimePicker}
                          name="startTime"
                        />
                      </div>

                      <div className={classes.dateTimePicker}>
                        <Field
                          higher
                          inputVariant="outlined"
                          label="End Time *"
                          component={StyledKeyboardTimePicker}
                          name="endTime"
                        />
                      </div>
                    </div>
                    <div className={classes.dateTimePickers}>
                      <AdvancedAutocomplete<RECURRING_TYPE_OPTION>
                        label={'Recurrence'}
                        options={recurringOptions}
                        disabled={!values.startDate}
                        value={recurringOptions.find(({ value }) => value === values.recurringType)}
                        getOptionInfo={option => ({
                          left: option.label,
                          id: option.value,
                        })}
                        disableOptionsAutoFiltering
                        disableInputFilter
                        clearable={false}
                        onChange={option => setFieldValue('recurringType', (option as RECURRING_TYPE_OPTION).value)}
                      />
                    </div>
                  </>
                )}
                {!values.isSchedulable && (
                  <>
                    <div className={classes.dateTimePickers}>
                      <div className={classes.dateTimePicker}>
                        <Field
                          inputVariant="outlined"
                          label="Start Date *"
                          higher
                          component={StyledKeyboardDatePicker}
                          name="startDate"
                        />
                      </div>

                      <div className={classes.dateTimePicker}>
                        <Field
                          higher
                          inputVariant="outlined"
                          label="End Date *"
                          component={StyledKeyboardDatePicker}
                          name="endDate"
                          minDate={moment(values.startDate || undefined)
                            .startOf('day')
                            .toDate()}
                          minDateMessage={'The end date cannot occur before the start date.'}
                        />
                      </div>
                    </div>

                    <div className={classes.dateTimePickers}>
                      <div className={classes.dateTimePicker}>
                        <Field
                          inputVariant="outlined"
                          label="Start Time *"
                          higher
                          component={StyledKeyboardTimePicker}
                          name="startTime"
                        />
                      </div>

                      <div className={classes.dateTimePicker}>
                        <Field
                          higher
                          inputVariant="outlined"
                          label="End Time *"
                          component={StyledKeyboardTimePicker}
                          name="endTime"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {values.isSchedulable && (
                <div className={classes.section}>
                  <span className={classes.sectionTitle}>Roles:</span>
                  <FieldArray name="roles">
                    {({ remove, push }) => (
                      <div className={classes.section}>
                        {values.roles.map((role, index) => (
                          <div className={classes.role} key={index}>
                            <DeleteIcon
                              className={classes.roleDelete}
                              onClick={() => {
                                remove(index)
                              }}
                            />
                            <div className={classes.roleQuantity}>
                              <StyledInput
                                value={role.quantity ? role.quantity.toString() : ''}
                                label={'Max'}
                                placeholder={'Number'}
                                onChange={quantity => {
                                  if (/^\d*$/.test(quantity)) {
                                    setFieldValue(`roles[${index}].quantity`, quantity ? parseInt(quantity) : null)
                                  }
                                }}
                              />
                            </div>
                            <div className={classes.roleSelector}>
                              <AdvancedAutocomplete<Role>
                                label={'Role'}
                                placeholder={'Select a Role'}
                                options={filter(allRoles, ({ id }) => !selectedRoles.has(id) || id === role.roleId)}
                                value={allRoles.find(({ id }) => id === role.roleId)}
                                getOptionInfo={option => ({
                                  left: option.name,
                                  id: option.id,
                                })}
                                onAdd={() => handleAdd(index)}
                                addNewText={'Add a new role'}
                                addNewIcon={<AddCircleIcon className={classes.addIcon} />}
                                onChange={option =>
                                  setFieldValue(`roles[${index}].roleId`, option ? (option as Role).id : null)
                                }
                              />
                            </div>
                          </div>
                        ))}
                        <InlineAddButton onClick={() => push({ id: null, quantity: null })} text="Add Role" />
                      </div>
                    )}
                  </FieldArray>
                </div>
              )}
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
