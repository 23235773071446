import { useCallback } from 'react'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { AddressType, OrganizationLocationReporting } from 'locations/types'
import useUpdateNonSchedulableOpportunity from 'volunteering/opportunities/hooks/useUpdateNonSchedulableOpportunity'
import { OpportunityPayload } from 'Event/interfaces'

export const useEndOpportunityFromLocations = (
  eagerReplace?: Action<OrganizationLocationReporting>,
  showNotification = true,
) => {
  const createNotification = useCreateApiNotification()
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity() as [
    (opportunity: Partial<OpportunityPayload>, encodedOccurrence: string) => Promise<any>,
  ]

  return useCallback(
    async (location: OrganizationLocationReporting) => {
      const undo = eagerReplace?.({ ...location, timesUsedActive: 0, encodedOccurrence: null, type: AddressType.Event })
      const notification = showNotification ? createNotification('Removing locations') : undefined
      try {
        await updateOpportunity(
          {
            geofencing: location.geofencing,
            startsAt: location.startsAt as string,
            name: location.name as string,
            endsAt: new Date(),
          },
          location.encodedOccurrence as string,
        )
        notification?.onSuccess('Location removed!')
      } catch (error) {
        notification?.onError(`Could not remove ${location.name || 'selected'} location`, error)
        undo?.()
      }
    },
    [createNotification, eagerReplace, showNotification, updateOpportunity],
  )
}

export default useEndOpportunityFromLocations
