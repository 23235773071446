import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import useRemoveVolunteerPrompt from 'volunteering/volunteers/hooks/useRemoveVolunteerPrompt'
import useRemoveVolunteerPromptOld from './useRemoveVolunteerPromptOld'
import { formatDate } from './utils'
import { AddMemberType } from 'civic-champs-shared/core/add-person/AddPersonFlow'
import useAddChampion from 'champion/hooks/useAddChampion'
import useAddVolunteerPrompt from 'volunteering/volunteers/hooks/useAddVolunteerPrompt'
import { useFetchVolunteers } from './useFetchVolunteers'
import useTagManagement from '../../../new-mentorship/hooks/useTagsManagement'
import { useGroups } from '../../../group/hooks/useGroups'
import { useFetchVolunteerCount } from './useFetchVolunteerCount'

const useVolunteersCollection = (filters, includeInactive) => {
  const [volunteers, operations, eventListeners] = useRemoteCollection()
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { fetchVolunteers, error } = useFetchVolunteers()
  const { fetchVolunteerCount } = useFetchVolunteerCount()
  const { getOrgTags } = useTagManagement()
  const { groups } = useGroups(undefined, false)

  const { syncCollection, eagerReplace } = operations
  const getVolunteers = useCallback(
    async (filters, showInactive) => {
      setLoadingProgress(0)
      setLoading(true)
      const { startDate, endDate } = filters
      const params = {
        showInactive,
      }

      if (startDate) {
        params.startDate = formatDate(startDate)
      }

      if (endDate) {
        params.endDate = formatDate(endDate)
      }

      const { count } = await fetchVolunteerCount(params)
      params.reporting = true
      const updatedVolunteers = []
      // not part of .env as different endpoints have different optimal values
      const limit = 1000
      for (let offset = 0; offset < count; offset += limit) {
        updatedVolunteers.push(...(await fetchVolunteers({ ...params, limit, offset })))
        setLoadingProgress(Math.min(100, Math.round(((offset + limit) / count) * 100)))
      }

      setLoadingProgress(100)
      setLoading(false)
      syncCollection(updatedVolunteers)
      setInitiallyLoaded(true)
    },
    [fetchVolunteerCount, fetchVolunteers, syncCollection],
  )

  const refresh = useCallback(() => getVolunteers(filters, includeInactive), [getVolunteers, filters, includeInactive])

  useEffect(() => {
    refresh()
  }, [refresh])

  //TODO should this be the regular API hooks, and then push
  //     the prompts up into the UI layer?
  const removeVolunteerOld = useRemoveVolunteerPromptOld(operations.eagerRemoveMany)
  const [addChampion] = useAddChampion()
  const addVolunteer = ({ id, userData, sendInvite }) => {
    const payload = id
      ? {
          userId: id,
          type: AddMemberType.ExistingUser,
          sendInvite,
        }
      : {
          ...userData,
          type: AddMemberType.NewUser,
          sendInvite,
        }
    return addChampion(payload).then(operations.eagerAdd)
  }
  const addVolunteerLegacy = useAddVolunteerPrompt(operations.eagerAdd)
  const removeVolunteer = useRemoveVolunteerPrompt(operations.eagerRemoveMany)
  const handleTagsChange = useCallback(
    async (personIds, addTagIds, removeTagIds) => {
      let orgTags = []
      if (addTagIds.length) {
        orgTags = await getOrgTags()
      }
      for (const personId of personIds) {
        const { tags, ...person } = volunteers.find(v => v.user.id === personId)
        const newTags = tags.filter(tag => !removeTagIds.includes(tag.id) && !addTagIds.includes(tag.id))
        newTags.push(...orgTags.filter(tag => addTagIds.includes(tag.id)))
        eagerReplace({
          ...person,
          tags: newTags,
        })
      }
    },
    [eagerReplace, getOrgTags, volunteers],
  )

  const handleGroupsAdded = useCallback(
    (personIds, groupIds) => {
      const groupsNames = groups.filter(group => groupIds.includes(group.id)).map(group => group.name)
      for (const personId of personIds) {
        const { groups, ...person } = volunteers.find(v => v.user.id === personId)
        const groupArray = groups.split(',')
        groupArray.push(groupsNames.filter(group => !groupArray.includes(group)))
        eagerReplace({
          ...person,
          groups: groupArray.join(','),
        })
      }
    },
    [eagerReplace, groups, volunteers],
  )

  return [
    {
      volunteers,
      loading,
      loadingProgress,
      error,
      initiallyLoaded,
    },
    {
      addVolunteer,
      addVolunteerLegacy,
      removeVolunteerOld,
      removeVolunteer,
      refresh,
      handleTagsChange,
      handleGroupsAdded,
    },
    eventListeners,
  ]
}

export default useVolunteersCollection
