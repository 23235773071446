import { useCallback, useEffect, useState } from 'react'

import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'

import { useCurrentOrg } from 'auth/hooks'
import { useAddActivityPrompt, useEditActivityPrompt, useRemoveActivitiesPrompt } from './index'
import { useFeatureEnabled } from '../../../core/feature/hooks'

export const useActivitiesCollection = filters => {
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [request, { error }] = useFetch()
  const [activities, operations, events] = useRemoteCollection()
  const isVolunteerTagsEnabled = useFeatureEnabled('ActivityVolunteerTags')
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection, eagerAdd, eagerReplace, eagerRemoveMany } = operations

  const getActivities = useCallback(
    async filters => {
      const { startDate, endDate, opportunityId, mapOccurrencesApproximately } = filters
      const params = {}
      if (mapOccurrencesApproximately) {
        params.mapOccurrencesApproximately = true
      }

      if (startDate) {
        params.from = format.dateForUrl(startDate)
      }

      if (endDate) {
        params.to = format.dateForUrl(endDate)
      }

      if (isVolunteerTagsEnabled) {
        params.includeVolunteerTags = true
      }

      let updatedActivities = []
      setLoadingProgress(0)
      setLoading(true)
      if (opportunityId) {
        updatedActivities = await request({
          url: `/opportunities/${opportunityId}/activities`,
          config: {
            queryStringParameters: params,
          },
        })
      } else {
        const { count } = await request({
          url: `/organizations/${organization.id}/activity-count`,
          config: {
            queryStringParameters: params,
          },
        })

        // not part of .env as different endpoints have different optimal values
        const limit = 1000
        for (let offset = 0; offset < count; offset += limit) {
          updatedActivities.push(
            ...(await request({
              url: `/organizations/${organization.id}/activities`,
              config: {
                queryStringParameters: { ...params, limit, offset },
              },
            })),
          )
          setLoadingProgress(Math.min(100, Math.round(((offset + limit) / count) * 100)))
        }
      }
      setLoadingProgress(100)
      setLoading(false)

      syncCollection(updatedActivities)
      setInitiallyLoaded(true)
    },
    [isVolunteerTagsEnabled, organization.id, request, syncCollection],
  )
  useEffect(() => {
    getActivities(filters)
  }, [filters, getActivities])

  const addActivity = useAddActivityPrompt(eagerAdd)
  const editActivity = useEditActivityPrompt(eagerReplace)
  const removeActivities = useRemoveActivitiesPrompt(eagerRemoveMany)

  return [
    {
      activities,
      loading,
      loadingProgress,
      error,
      initiallyLoaded,
    },
    {
      addActivity,
      editActivity,
      removeActivities,
    },
    events,
  ]
}

export default useActivitiesCollection
